import React from "react";
import UtilService from "../services/util";
import OpenNotification from "./common/CommonButton/OpenNotification";
import FrontWrapper from "../components/FrontStatic/common/FrontWrapper";
import FrontFooter from "../components/FrontStatic/common/Front-Footer";
import IntlMessages from "../util/IntlMessages";
import {Modal} from "antd";
import _ from "lodash";

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            data: {},
            showEmail: false,
        };
    }

    handleSubmit = () => {
        let self = this;
        let api = "admin/feedback/create";
        let request = this.state.data;
        _.each(request, (val, key) => {
            if (val === "") delete request[key];
        });

        let objData = {
            method: "post",
            url: api,
            request: request,
        };

        if (_.isEmpty(request)) {
            return OpenNotification({
                type: "error",
                title: "Please fill the form.",
            });
        } else if (request.contactedFeedback && !request.contactedByEmail) {
            return OpenNotification({
                type: "error",
                title: "Please enter email address.",
            });
        } else if (
            request.contactedByEmail &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(request.contactedByEmail)
        ) {
            return OpenNotification({
                type: "error",
                title: "Please enter valid email address.",
            });
        } else {
            UtilService.callApi(objData, function (err, data) {
                if (err) throw err;
                else if (data && data.code === "OK") {
                    self.setState({success: true});
                }
                self.resetForm();
            });
        }
    };

    resetForm = () => {
        let dt = this.state.data;
        dt["easyInfoComment"] = "";
        dt["featureComment"] = "";
        dt["notGoodExpComment"] = "";
        dt["contactedByEmail"] = "";
        for (let i = 0; i < 600; i++) {
            if (document.getElementById("radio_" + i))
                document.getElementById("radio_" + i).checked = false;
            if (document.getElementById("toggle_" + i))
                document.getElementById("toggle_" + i).checked = false;
        }
        this.setState({data: dt});
    };

    render() {
        let {data} = this.state;
        return (
            <FrontWrapper location={this.props.location} isForDevice={this.props.isForDevice}>
                <div className="scroll-content-wrapper" data-scrollbar>
                    <div className="page-main page-current">
                        <div className="page-toload legacy-page" data-bodyclassname="legacy">
                            <main className="page-content" role="main">
                                <div
                                    className={
                                        this.props.isForDevice
                                            ? "feedback-wrapper-info remove-space"
                                            : "feedback-wrapper-info"
                                    }
                                >
                                    <div className="feedback-front-page">
                                        <div className="page-head feedback-front-page-title mb-100 mb-xs-40">
                                            <h3>
                                                {this.props.isForDevice ? (
                                                    ""
                                                ) : (
                                                    <IntlMessages id="app.Feedback"/>
                                                )}
                                            </h3>
                                            <p className="feedbackDec">
                                                Help us shine better!
                                                <br/>
                                                You made HK the leading diamond manufacturing
                                                company across the world.
                                                <br/>
                                                Now help us make hk.co exceptional in the industry
                                                by giving us your valuable feedback.
                                                <br/>
                                                We genuinely appreciate your inputs!
                                            </p>
                                        </div>
                                        <div className="feedback-box">
                                            <div className="main_icon_part">
                                                <div className="feedbackOption">
                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft"></div>
                                                        <div className="feedbackRight right_rating_part">
                                                            <div className="icon_block">
                                                                <div class="row_num">1</div>
                                                            </div>
                                                            <div className="icon_block">
                                                                <div class="row_num">2</div>
                                                            </div>
                                                            <div className="icon_block">
                                                                <div class="row_num">3</div>
                                                            </div>
                                                            <div className="icon_block">
                                                                <div class="row_num">4</div>
                                                            </div>
                                                            <div className="icon_block">
                                                                <div class="row_num">5</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    1. Please rate your experience
                                                                    on hk.co website speed
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part">
                                                            <div className="">
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_101"
                                                                                    name="my-radio1"
                                                                                    value="1"
                                                                                    onChange={(e) =>
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "speedExp"
                                                                                                ] = 1)
                                                                                            : (data[
                                                                                                "speedExp"
                                                                                                ] =
                                                                                                "")
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_101"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_101">
                                                                        <div class="rate_icon mb-25">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 330 330"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    id="XMLID_23_"
                                                                                    d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165c90.982,0,165-74.019,165-165S255.982,0,165,0z M81,133 c0-5.26,2.14-10.42,5.859-14.141C90.58,115.14,95.73,113,101,113c5.26,0,10.42,2.14,14.14,5.859C118.869,122.58,121,127.74,121,133 c0,5.26-2.131,10.42-5.86,14.14c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C83.14,143.42,81,138.26,81,133z M226.518,226.518c-5.857,5.857-15.355,5.857-21.213,0c-10.767-10.766-25.08-16.694-40.305-16.694 c-15.227,0-29.539,5.929-40.306,16.694c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.607-4.394 c-5.858-5.857-5.858-15.355-0.001-21.213c16.432-16.432,38.281-25.482,61.519-25.482c0.001,0-0.001,0,0.001,0 c23.238,0,45.086,9.05,61.518,25.482C232.375,211.162,232.375,220.66,226.518,226.518z M243.14,147.14 c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C211.13,143.42,209,138.26,209,133 c0-5.26,2.13-10.42,5.859-14.141C218.58,115.14,223.73,113,229,113c5.26,0,10.42,2.14,14.14,5.859 c3.73,3.721,5.86,8.881,5.86,14.141C249,138.26,246.869,143.42,243.14,147.14z"
                                                                                ></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_102"
                                                                                    name="my-radio1"
                                                                                    value="2"
                                                                                    onChange={(e) =>
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "speedExp"
                                                                                                ] = 2)
                                                                                            : (data[
                                                                                                "speedExp"
                                                                                                ] =
                                                                                                "")
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_102"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_102">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_103"
                                                                                    name="my-radio1"
                                                                                    value="3"
                                                                                    onChange={(e) =>
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "speedExp"
                                                                                                ] = 3)
                                                                                            : (data[
                                                                                                "speedExp"
                                                                                                ] =
                                                                                                "")
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_103"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_103">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_104"
                                                                                    name="my-radio1"
                                                                                    value="4"
                                                                                    onChange={(e) =>
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "speedExp"
                                                                                                ] = 4)
                                                                                            : (data[
                                                                                                "speedExp"
                                                                                                ] =
                                                                                                "")
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_104"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_104">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_105"
                                                                                    name="my-radio1"
                                                                                    value="5"
                                                                                    onChange={(e) =>
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "speedExp"
                                                                                                ] = 5)
                                                                                            : (data[
                                                                                                "speedExp"
                                                                                                ] =
                                                                                                "")
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_105"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_105">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    2. Did you find the information
                                                                    you were looking for easily on
                                                                    hk.co?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part withoutRating">
                                                            <div className="d-flex d-xs-block align-items-center">
                                                                <div className="form-group d-flex fdb-btn">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="toggle_1"
                                                                        className="chkbx-toggle"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked)
                                                                                data[
                                                                                    "easyInfo"
                                                                                    ] = true;
                                                                            document.getElementById(
                                                                                "toggle_2"
                                                                            ).checked = false;
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="toggle_1"
                                                                    />
                                                                    <span
                                                                        className="toggle-label"
                                                                        onClick={() =>
                                                                            document
                                                                                .getElementById(
                                                                                    "toggle_1"
                                                                                )
                                                                                .click()
                                                                        }
                                                                    >
                                                                        Yes
                                                                    </span>
                                                                </div>
                                                                <div className="form-group d-flex fdb-btn">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="toggle_2"
                                                                        className="chkbx-toggle"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked)
                                                                                data[
                                                                                    "easyInfo"
                                                                                    ] = false;
                                                                            document.getElementById(
                                                                                "toggle_1"
                                                                            ).checked = false;
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="toggle_2"
                                                                    />
                                                                    <span
                                                                        className="toggle-label"
                                                                        onClick={() =>
                                                                            document
                                                                                .getElementById(
                                                                                    "toggle_2"
                                                                                )
                                                                                .click()
                                                                        }
                                                                    >
                                                                        No
                                                                    </span>
                                                                </div>
                                                                <div className="improve-detail">
                                                                    <textarea
                                                                        placeholder="Comment"
                                                                        id="val3"
                                                                        value={
                                                                            data["easyInfoComment"]
                                                                        }
                                                                        onChange={(e) => {
                                                                            let dt = this.state
                                                                                .data;
                                                                            dt["easyInfoComment"] =
                                                                                e.target.value;
                                                                            this.setState({
                                                                                data: dt,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    3. If you were to review the
                                                                    website, what score would you
                                                                    give it?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part">
                                                            <div className="">
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_201"
                                                                                    name="my-radio2"
                                                                                    value="1"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "reviewSite"
                                                                                                ] = 1)
                                                                                            : (data[
                                                                                                "reviewSite"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_201"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_201">
                                                                        <div class="rate_icon mb-25">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 330 330"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    id="XMLID_23_"
                                                                                    d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165c90.982,0,165-74.019,165-165S255.982,0,165,0z M81,133 c0-5.26,2.14-10.42,5.859-14.141C90.58,115.14,95.73,113,101,113c5.26,0,10.42,2.14,14.14,5.859C118.869,122.58,121,127.74,121,133 c0,5.26-2.131,10.42-5.86,14.14c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C83.14,143.42,81,138.26,81,133z M226.518,226.518c-5.857,5.857-15.355,5.857-21.213,0c-10.767-10.766-25.08-16.694-40.305-16.694 c-15.227,0-29.539,5.929-40.306,16.694c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.607-4.394 c-5.858-5.857-5.858-15.355-0.001-21.213c16.432-16.432,38.281-25.482,61.519-25.482c0.001,0-0.001,0,0.001,0 c23.238,0,45.086,9.05,61.518,25.482C232.375,211.162,232.375,220.66,226.518,226.518z M243.14,147.14 c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C211.13,143.42,209,138.26,209,133 c0-5.26,2.13-10.42,5.859-14.141C218.58,115.14,223.73,113,229,113c5.26,0,10.42,2.14,14.14,5.859 c3.73,3.721,5.86,8.881,5.86,14.141C249,138.26,246.869,143.42,243.14,147.14z"
                                                                                ></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_202"
                                                                                    name="my-radio2"
                                                                                    value="2"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "reviewSite"
                                                                                                ] = 2)
                                                                                            : (data[
                                                                                                "reviewSite"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_202"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_202">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_203"
                                                                                    name="my-radio2"
                                                                                    value="3"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "reviewSite"
                                                                                                ] = 3)
                                                                                            : (data[
                                                                                                "reviewSite"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_203"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_203">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_204"
                                                                                    name="my-radio2"
                                                                                    value="4"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "reviewSite"
                                                                                                ] = 4)
                                                                                            : (data[
                                                                                                "reviewSite"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_204"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_204">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_205"
                                                                                    name="my-radio2"
                                                                                    value="5"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "reviewSite"
                                                                                                ] = 5)
                                                                                            : (data[
                                                                                                "reviewSite"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_205"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_205">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    4. Please rate your diamonds
                                                                    purchase experience
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part">
                                                            <div className="">
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_301"
                                                                                    name="my-radio3"
                                                                                    value="1"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "purchaseExp"
                                                                                                ] = 1)
                                                                                            : (data[
                                                                                                "purchaseExp"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_301"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_301">
                                                                        <div class="rate_icon mb-25">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 330 330"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    id="XMLID_23_"
                                                                                    d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165c90.982,0,165-74.019,165-165S255.982,0,165,0z M81,133 c0-5.26,2.14-10.42,5.859-14.141C90.58,115.14,95.73,113,101,113c5.26,0,10.42,2.14,14.14,5.859C118.869,122.58,121,127.74,121,133 c0,5.26-2.131,10.42-5.86,14.14c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C83.14,143.42,81,138.26,81,133z M226.518,226.518c-5.857,5.857-15.355,5.857-21.213,0c-10.767-10.766-25.08-16.694-40.305-16.694 c-15.227,0-29.539,5.929-40.306,16.694c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.607-4.394 c-5.858-5.857-5.858-15.355-0.001-21.213c16.432-16.432,38.281-25.482,61.519-25.482c0.001,0-0.001,0,0.001,0 c23.238,0,45.086,9.05,61.518,25.482C232.375,211.162,232.375,220.66,226.518,226.518z M243.14,147.14 c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C211.13,143.42,209,138.26,209,133 c0-5.26,2.13-10.42,5.859-14.141C218.58,115.14,223.73,113,229,113c5.26,0,10.42,2.14,14.14,5.859 c3.73,3.721,5.86,8.881,5.86,14.141C249,138.26,246.869,143.42,243.14,147.14z"
                                                                                ></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_302"
                                                                                    name="my-radio3"
                                                                                    value="2"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "purchaseExp"
                                                                                                ] = 2)
                                                                                            : (data[
                                                                                                "purchaseExp"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_302"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_302">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_303"
                                                                                    name="my-radio3"
                                                                                    value="3"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "purchaseExp"
                                                                                                ] = 3)
                                                                                            : (data[
                                                                                                "purchaseExp"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_303"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_303">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_304"
                                                                                    name="my-radio3"
                                                                                    value="4"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "purchaseExp"
                                                                                                ] = 4)
                                                                                            : (data[
                                                                                                "purchaseExp"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_304"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_304">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_305"
                                                                                    name="my-radio3"
                                                                                    value="5"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "purchaseExp"
                                                                                                ] = 5)
                                                                                            : (data[
                                                                                                "purchaseExp"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_305"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_305">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    5. How's your diamond search
                                                                    experience with us?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight withoutRating">
                                                            <div className="feedback-form-width d-flex expirenceBlock">
                                                                <div className="form-group feedback-block">
                                                                    <input
                                                                        className="custom-radio"
                                                                        id="radio_401"
                                                                        type="radio"
                                                                        name="my-radio9"
                                                                        onChange={(e) => {
                                                                            e.target.checked
                                                                                ? (data[
                                                                                    "searchExp"
                                                                                    ] = "Easy")
                                                                                : (data[
                                                                                    "searchExp"
                                                                                    ] = "");
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="radio_401"
                                                                    >
                                                                        <span/> Easy
                                                                    </label>
                                                                </div>
                                                                <div className="form-group feedback-block">
                                                                    <input
                                                                        className="custom-radio"
                                                                        type="radio"
                                                                        id="radio_402"
                                                                        name="my-radio9"
                                                                        onChange={(e) => {
                                                                            e.target.checked
                                                                                ? (data[
                                                                                    "searchExp"
                                                                                    ] = "Normal")
                                                                                : (data[
                                                                                    "searchExp"
                                                                                    ] = "");
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="radio_402"
                                                                    >
                                                                        <span/> Normal
                                                                    </label>
                                                                </div>
                                                                <div className="form-group feedback-block">
                                                                    <input
                                                                        className="custom-radio"
                                                                        type="radio"
                                                                        id="radio_403"
                                                                        name="my-radio9"
                                                                        onChange={(e) => {
                                                                            e.target.checked
                                                                                ? (data[
                                                                                    "searchExp"
                                                                                    ] = "Tough")
                                                                                : (data[
                                                                                    "searchExp"
                                                                                    ] = "");
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="radio_403"
                                                                    >
                                                                        <span/> Tough
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    6. What features would you
                                                                    suggest to add on hk.co?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part withoutRating">
                                                            <div className="improve-detail">
                                                                <textarea
                                                                    placeholder="Comment"
                                                                    id="val3"
                                                                    value={data["featureComment"]}
                                                                    onChange={(e) => {
                                                                        let dt = this.state.data;
                                                                        dt["featureComment"] =
                                                                            e.target.value;
                                                                        this.setState({data: dt});
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    7. Where does hk.co website
                                                                    stand in comparison in the
                                                                    industry?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part">
                                                            <div className="">
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_501"
                                                                                    name="my-radio5"
                                                                                    value="1"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "compare"
                                                                                                ] = 1)
                                                                                            : (data[
                                                                                                "compare"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_501"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_501">
                                                                        <div class="rate_icon mb-25">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 330 330"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    id="XMLID_23_"
                                                                                    d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165c90.982,0,165-74.019,165-165S255.982,0,165,0z M81,133 c0-5.26,2.14-10.42,5.859-14.141C90.58,115.14,95.73,113,101,113c5.26,0,10.42,2.14,14.14,5.859C118.869,122.58,121,127.74,121,133 c0,5.26-2.131,10.42-5.86,14.14c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C83.14,143.42,81,138.26,81,133z M226.518,226.518c-5.857,5.857-15.355,5.857-21.213,0c-10.767-10.766-25.08-16.694-40.305-16.694 c-15.227,0-29.539,5.929-40.306,16.694c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.607-4.394 c-5.858-5.857-5.858-15.355-0.001-21.213c16.432-16.432,38.281-25.482,61.519-25.482c0.001,0-0.001,0,0.001,0 c23.238,0,45.086,9.05,61.518,25.482C232.375,211.162,232.375,220.66,226.518,226.518z M243.14,147.14 c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C211.13,143.42,209,138.26,209,133 c0-5.26,2.13-10.42,5.859-14.141C218.58,115.14,223.73,113,229,113c5.26,0,10.42,2.14,14.14,5.859 c3.73,3.721,5.86,8.881,5.86,14.141C249,138.26,246.869,143.42,243.14,147.14z"
                                                                                ></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_502"
                                                                                    name="my-radio5"
                                                                                    value="2"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "compare"
                                                                                                ] = 2)
                                                                                            : (data[
                                                                                                "compare"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_502"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_502">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_503"
                                                                                    name="my-radio5"
                                                                                    value="3"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "compare"
                                                                                                ] = 3)
                                                                                            : (data[
                                                                                                "compare"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_503"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_503">
                                                                        <div class="rate_icon mb-25 black_sml">
                                                                            <svg
                                                                                id="bold_v2"
                                                                                enable-background="new 0 0 24 24"
                                                                                height="512"
                                                                                viewBox="0 0 24 24"
                                                                                width="512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_504"
                                                                                    name="my-radio5"
                                                                                    value="4"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "compare"
                                                                                                ] = 4)
                                                                                            : (data[
                                                                                                "compare"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_504"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_504">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="icon_block">
                                                                    <div class="radi_btn">
                                                                        <div>
                                                                            <div class="form-group">
                                                                                <input
                                                                                    class="custom-radio"
                                                                                    type="radio"
                                                                                    id="radio_505"
                                                                                    name="my-radio5"
                                                                                    value="5"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target
                                                                                            .checked
                                                                                            ? (data[
                                                                                                "compare"
                                                                                                ] = 5)
                                                                                            : (data[
                                                                                                "compare"
                                                                                                ] =
                                                                                                "");
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    class="feedback-label"
                                                                                    for="radio_505"
                                                                                >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="radio_505">
                                                                        <div class="rate_icon mb-25 green_sml">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xlink="http://www.w3.org/1999/xlink"
                                                                                x="0px"
                                                                                y="0px"
                                                                                viewBox="0 0 455 455"
                                                                                space="preserve"
                                                                            >
                                                                                <path
                                                                                    d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z"></path>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                                <g></g>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    8. If we have not delivered a
                                                                    good experience, what can we do
                                                                    to get you back?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part withoutRating">
                                                            <div className="improve-detail">
                                                                <textarea
                                                                    placeholder="Comment"
                                                                    id="val3"
                                                                    value={
                                                                        data["notGoodExpComment"]
                                                                    }
                                                                    onChange={(e) => {
                                                                        let dt = this.state.data;
                                                                        dt["notGoodExpComment"] =
                                                                            e.target.value;
                                                                        this.setState({data: dt});
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="feedbackFromItem">
                                                        <div className="feedbackLeft">
                                                            <div className="page-head">
                                                                <h3>
                                                                    9. Would you like to be
                                                                    contacted by a Customer Service
                                                                    team member regarding your
                                                                    feedback?
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackRight right_rating_part withoutRating">
                                                            <div className="d-flex d-xs-block">
                                                                <div className="form-group d-flex fdb-btn">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="toggle_3"
                                                                        className="chkbx-toggle"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                data[
                                                                                    "contactedFeedback"
                                                                                    ] = true;
                                                                                this.setState({
                                                                                    showEmail: true,
                                                                                });
                                                                            } else {
                                                                                this.setState({
                                                                                    showEmail: false,
                                                                                });
                                                                            }

                                                                            document.getElementById(
                                                                                "toggle_4"
                                                                            ).checked = false;
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="toggle_3"
                                                                    />
                                                                    <span
                                                                        className="toggle-label"
                                                                        onClick={() =>
                                                                            document
                                                                                .getElementById(
                                                                                    "toggle_3"
                                                                                )
                                                                                .click()
                                                                        }
                                                                    >
                                                                        Yes
                                                                    </span>
                                                                </div>
                                                                <div className="form-group d-flex fdb-btn">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="toggle_4"
                                                                        className="chkbx-toggle"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked)
                                                                                data[
                                                                                    "contactedFeedback"
                                                                                    ] = false;
                                                                            document.getElementById(
                                                                                "toggle_3"
                                                                            ).checked = false;
                                                                            this.setState({
                                                                                showEmail: false,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="feedback-label"
                                                                        htmlFor="toggle_4"
                                                                    />
                                                                    <span
                                                                        className="toggle-label"
                                                                        onClick={() =>
                                                                            document
                                                                                .getElementById(
                                                                                    "toggle_4"
                                                                                )
                                                                                .click()
                                                                        }
                                                                    >
                                                                        No
                                                                    </span>
                                                                </div>
                                                                {this.state.showEmail && (
                                                                    <div
                                                                        className="form-group"
                                                                        style={{
                                                                            paddingLeft: "30px",
                                                                        }}
                                                                    >
                                                                        <div className="form-control">
                                                                            <input
                                                                                className="pincode-number"
                                                                                id="eon"
                                                                                type="text"
                                                                                placeholder="Please enter Email"
                                                                                value={
                                                                                    data[
                                                                                        "contactedByEmail"
                                                                                        ]
                                                                                        ? data[
                                                                                            "contactedByEmail"
                                                                                            ]
                                                                                        : ""
                                                                                }
                                                                                onChange={(e) => {
                                                                                    let dt = this
                                                                                        .state.data;
                                                                                    dt[
                                                                                        "contactedByEmail"
                                                                                        ] =
                                                                                        e.target.value;
                                                                                    this.setState({
                                                                                        data: dt,
                                                                                    });
                                                                                }}
                                                                                //contactedByEmail
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feedbackDec bottomfeedbackdec">
                                                    <h2>
                                                        Thank you for being an awesome customer!
                                                    </h2>
                                                    <p>
                                                        We wanted to thank you for supporting us
                                                        with sparing your precious time to improve
                                                        our website.{" "}
                                                    </p>
                                                </div>
                                                <div className="container mt-30">
                                                    <div
                                                        className="form_blue_btn"
                                                        style={{textAlign: "right"}}
                                                    >
                                                        <div className="form_blue_btn upload_btn">
                                                            <button
                                                                className="button primary-fill"
                                                                onClick={this.handleSubmit}
                                                            >
                                                                <IntlMessages id="app.feedSubmit"/>
                                                            </button>
                                                        </div>
                                                        <div className="btn_white">
                                                            <button
                                                                className="button secondry-fill"
                                                                onClick={this.resetForm}
                                                            >
                                                                <IntlMessages id="app.feedReset"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!this.props.isForDevice && <FrontFooter/>}
                                <Modal
                                    className="no-title-modal"
                                    visible={this.state.success}
                                    centered
                                    title={null}
                                    onCancel={() => this.setState({success: false})}
                                    footer={null}
                                    width={580}
                                >
                                    <h3 align="center" style={{color: "rgba(0,0,0,0.70)"}}>
                                        Thank you for your time and interest in providing us
                                        feedback. We appreciate your feedback.
                                    </h3>
                                    <br/>
                                    <div
                                        align="center"
                                        style={{
                                            marginRight: "60px",
                                            marginLeft: "60px",
                                        }}
                                    >
                                        <button
                                            className="modal__btn button primary-fill"
                                            style={{padding: "5px 15px"}}
                                            onClick={() => this.setState({success: false})}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Modal>
                            </main>
                            <div className="preload" aria-hidden="true"/>
                        </div>
                    </div>
                    <div className="page-main page-next" aria-hidden="true"/>
                    <div className="page-main page-prev" aria-hidden="true"/>
                    <footer className="footer" role="contentinfo"/>
                </div>
            </FrontWrapper>
        );
    }
}

export default Feedback;
